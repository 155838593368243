import request from '@/utils/request'

const housesApi = {
  // get houses info
  base: '/api/v1',
  houses: '/api/v1/rest/houses',
  findByKeyword: '/api/v1/rest/houses/search/findByKeyword',
  query: '/api/v1/house/query',
  findManagerByHouse: '/api/v1/house/findManagerByHouse'
}

export function houses (param) {
  param = { ...param, ...{ projection: 'withAll' } }
  const url = request.requestFormat(housesApi.houses, param)
  return request({
    url,
    method: 'get'
  })
}

export function deleteHousesManager (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/managers/${param.managerId}`,
    method: 'delete'
  })
}

export function housesAll () {
  return request({
    url: housesApi.houses,
    method: 'get'
  })
}

export function houseAdd (param) {
  return request({
    url: housesApi.houses,
    method: 'post',
    data: param
  })
}

export function findManagerByHouse (param) {
  return request({
    url: housesApi.findManagerByHouse,
    method: 'post',
    data: param
  })
}

export function houseGet (id) {
  return request({
    url: housesApi.houses + `/${id}`,
    method: 'get'
  })
}

export function editHouses (id, data) {
  return request({
    url: housesApi.houses + `/${id}`,
    method: 'patch',
    data
  })
}

export function houseEdit (id, data) {
  return request({
    url: housesApi.houses + `/${id}`,
    method: 'patch',
    data
  })
}
export function delHouses (param) {
  return request({
    url: housesApi.houses + `/${param.id}`,
    method: 'delete'
  })
}

export function findByIdHouse (id) {
  return request({
    url: housesApi.houses + `/${id}?projection=withAll`,
    method: 'get'
  })
}

export function saveHouse (param) {
  return request({
    url: housesApi.houses + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

export function queryHouse (param) {
  const url = request.requestFormat(housesApi.query, param)
  return request({
    url,
    method: 'get'
  })
}

export function inHouses (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}/${param.requestId}`,
    method: param.method
  })
}

export function houseSub (method, param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}`,
    method: method,
    data: param.data
  })
}

export function getHouseSub (houseId, name) {
  return request({
    url: housesApi.houses + `/${houseId}/${name}`,
    method: 'get'
  })
}

export function putHouseSub (houseId, name, data) {
  return request({
    url: housesApi.houses + `/${houseId}/${name}`,
    method: 'put',
    data
  })
}

export function putHouseResident (houseId, data) {
  return request({
    url: housesApi.houses + `/${houseId}/residents`,
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function bindHouse (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}`,
    method: param.method,
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data: param.data
  })
}

export function housesManagers (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/managers`,
    method: 'post',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data: param.data
  })
}

export function delHouseSub (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}/${param.requestId}`,
    method: 'delete'
  })
}
